<template>
    <div>
        <section>
            <b-card>
                <div class="row">
                    <div class="col-12 col-md-5 mb-3">
                        <MainCard />
                    </div>
                    <div class="col-12 col-md-7 mb-3">
                        <Chart />
                    </div>
                </div>
            </b-card>
        </section>
        <div class="row mt-2">
            <div class="col-12 col-sm-2 text-right pt-2 offset-sm-10">
                <b-button variant="primary" size="lg" @click="modalCreate = !modalCreate">
                    Create course
                </b-button>
            </div>
        </div>
        <div v-if="loading == false">
            <div class="row mt-4" v-if="courses.length > 0">
                <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-3" v-for="course in courses" :key="course.id">
                    <CardCourse :course="course" />
                </div>
            </div>
            <Empty type="card" v-else/>
        </div>
        <Loading v-else/>
        <b-modal title="Creating course" size="lg" v-model="modalCreate" hide-footer centered>
            <Form @close="modalCreate = false"/>
        </b-modal>
    </div>
</template>
<script>
import CardCourse from './CardCourse.vue'
import Form from './Form.vue'
import MainCard from './MainCard.vue'
import Chart from './Chart.vue'
import { mapActions, mapState } from 'vuex'
export default {
    components: {
        Form,
        CardCourse,
        MainCard,
        Chart
    },
    data () {
        return {
            loading: false,
            modalCreate: false,
            pagination: {
                course: null,
                category: null,
                page: 1
            }
        }
    },
    created () {
        this.getData()
    },
    methods: {
        ...mapActions('academy', ['getCourses']),
        getData () {
            this.loading = true
            this.getCourses(this.pagination).then(() => {
                this.loading = false
            })
        }
    },
    computed: {
        ...mapState('academy', ['courses'])
    }
}
</script>